import axios from '../instance'

export interface EXPORT_PEAK_PARAMS {
    currentMonth: string | Date
}

export const exportPeakReport = async (params: EXPORT_PEAK_PARAMS): Promise<any> => {
	try {
		const response = await axios.post('/admin/peak-report', {}, { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface ICommissionPayload {
    currentMonth: string | Date
    referralCode: string
}


export const exportCommission = async (payload: ICommissionPayload): Promise<any> => {
	try {
		const response = await axios.post('/admin/referral-commission', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
